import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';
import Bio from '../components/bio';

export default class BlogListTemplate extends React.Component {
  renderPaging() {
    const { currentPage, numPages } = this.props.pageContext;
    const prevPage = currentPage - 1 === 1 ? '/' : `/${currentPage - 1}/`;
    const nextPage = `/${currentPage + 1}/`;
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === numPages;

    return (
      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {!isFirstPage && (
              <Link key={`prev`} to={prevPage} rel="prev">
                Previous
              </Link>
            )}
          </li>
          {[...Array(numPages)].map((_val, index) => {
            const pageNum = index + 1;
            return (
              <li key={`listing-page-${pageNum}`}>
                <Link
                  to={pageNum === 1 ? '/' : `/${pageNum}/`}
                  activeClassName="active"
                >
                  {pageNum}
                </Link>
              </li>
            );
          })}
          <li>
            {!isLastPage && (
              <Link key={`next`} to={`${nextPage}`} rel="next">
                Next
              </Link>
            )}
          </li>
        </ul>
      </nav>
    );
  }

  render() {
    const { data } = this.props;
    const posts = this.props.data.allMarkdownRemark.edges;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <article key={node.fields.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <small>{node.frontmatter.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>
          );
        })}
        {this.renderPaging()}
      </Layout>
    );
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {
          published: { eq: true }
      },
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
